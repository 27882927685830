const ServiceData = [
        {
          id:"is1",
          icon:"fas fa-braille",
          title: "Omni-Channel Distribution",
          text: "Actovos delivers your content via SMS, Phone call, and Emails. Select one avenue or all of them!",
          iconBackground:"#136de8"
        },
        {
          id:"is2",
          icon:"fas fa-file-import",
          title: "Import Contacts",
          text:"Import your list of contacts in seconds.",
          iconBackground:"#136de8"
        },
        {
          id:"is3",
          icon:"fas fa-comment-dots",
          title: "Instant Feedback",
          text: "Receive responses from your audience within seconds and filter based on their responses.",
          iconBackground:"#136de8"
        },
        {
          id:"is4",
          icon:"fas fa-clone",
          title: "Custom Templates",
          text: "Create custom templates for any event type. Use that template to craft your message, pre-select your audience, and create custom workflows based on your recipients responses.",
          iconBackground:"#136de8"
        },
        {
          id:"is5",
          icon:"fas fa-globe-americas",
          title: "Geo-Searches",
          text:"Search for recipients based on the address provided and send notifications to only those individuals.",
          iconBackground:"#136de8"
        },
        {
          id:"is6",
          icon:"fas fa-user-friends",
          title: "Contact History",
          text:"View the contact history and responses for all of your recipients.",
          iconBackground:"#136de8"
        },
        {
          id:"is7",
          icon:"fas fa-keyboard",
          title:"Short Code",
          text:"Elect to use a short code number.",
          iconBackground:"#136de8"
        },
        {
          id:"is8",
          icon:"fas fa-mobile-alt",
          title:"Sticky Phone Numbers",
          text:"Phone numbers will remain consistent throughout notifying recipients so they always know who is contacting them.",
          iconBackground:"#136de8"
        }
    
    ]
  export default ServiceData;